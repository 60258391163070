import React, { useState } from "react";
import Swal from "sweetalert2";
import { formatNumber, handleIpoApproval } from "../../../firebaseConfig/firestore";
import CurrencyInput from "react-currency-input-field";
import "./style.css";

export default function InvestApprovalModal({ onClose, ipo, userId, fetchIpoRequests }) {
  const [numberOfShares, setNumberOfShares] = useState(ipo.numberOfShares);
  const [sellPrice, setSellPrice] = useState(ipo.sharePrice || 0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSellIpo = async (e) => {
    e.preventDefault();
    if (numberOfShares <= 0 || sellPrice <= 0) {
      setError("Please ensure the number of shares and investment price are valid.");
      return;
    }
    const sellPriceStr = String(sellPrice);
    const sellPriceNumber = parseFloat(sellPriceStr.replace(/\.00$/, ''));
  
    const saleData = {
      ...ipo,
      type: "Invest", 
      status: "Approved",
      sharePrice: sellPriceNumber,
    };
    setIsLoading(true);

    try {
      await handleIpoApproval(userId, ipo.id, saleData);
      Swal.fire({
        icon: "success",
        title: "Investment Approved",
        text: "You have successfully approved an investment.",
        showConfirmButton: false,
        timer: 2000,
      });
      fetchIpoRequests();
      setNumberOfShares(0);
      setSellPrice(ipo.sharePrice || 0);
      onClose();
    } catch (error) {
      setError(`There was an issue approving the investment request. Try again later.`);
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNumberOfSharesChange = (value) => {
    setNumberOfShares(value);
  };

  const handleInvestPriceChange = (value) => {
    setSellPrice(value);
  };


  const totalCost = (ipo.numberOfShares * sellPrice) || 0;

  return (
    <>
      <div className="invest_ipo_overlay" onClick={(e) => e.stopPropagation()}>
        <div className="invest_ipo_modal">
          <div className="section_header">
            <h2 className="title">Approve Investment</h2>
          </div>
          <form onSubmit={handleSellIpo} className="section_body">
            <div className="input_group">
            <label htmlFor="numberOfShares">Total Cost:</label>
              <p className="reg_text">$ {formatNumber(totalCost)}</p>
            </div>
             <div className="input_group">
              <label htmlFor="numberOfShares">Number of Shares:</label>
              <input
                type="number"
                name="numberOfShares"
                id="numberOfShares"
                value={ipo.numberOfShares}
                onChange={handleNumberOfSharesChange}
                min="0"
              />
            </div>
            <div className="input_group">
              <label htmlFor="sellPrice">Sell Price Per Share:</label>
              <CurrencyInput
                id="sellPrice"
                name="sellPrice"
                prefix="$"
                defaultValue={ipo.sharePrice || 0}
                decimalsLimit={2}
                onValueChange={handleInvestPriceChange}
              />
            </div>
            {message && <p className="success_msg">{message}</p>}
            {error && <p className="error_msg">{error}</p>}
            <div className="buttons_wrap">
              <button type="submit" className="submit_btn" disabled={isLoading}>
                Approve
              </button>
              {isLoading && <div className="spinner"></div>}
              <button type="button" onClick={onClose} className="cancel_btn">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
